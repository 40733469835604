.Question {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  &__buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
}
