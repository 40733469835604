.Button {
  background-color: lightcoral;
  color: black;
  border: 0;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  transition: all 0.2s ease;

  &:hover,
  &:active {
    // background-color: darkred;
    // color: white;
    outline: none;
    box-shadow: none;
  }

  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: none;
  }
}
