.End {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  .Button {
    background-color: lightgreen;
  }
}
